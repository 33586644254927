import * as LDClient from 'launchdarkly-js-client-sdk';
import _ from 'lodash';

export const FEATURE_FLAGS = {
  HIDDEN_WIDGET_FIELDS: 'UITEAM-667-hidden-widget-fields',
  SHOW_APP_NAV: 'UITEAM-698-update-app-nav-hub',
  MACHINE_LEARNING: 'DAS-57-ml-app-create-and-delete',
  APP_STUDIO: 'ask-7-app-studio-app-type',
  INTEGRATION_ROLES: 'CSQ-309-mf-roles',
  SETTINGS: 'CSQ-773-settings-page',
  LUCIDWORKS_AI_USAGE: 'sss-690-lucidworks-ai-usage',
  ASK_118_EM_INSTANCE: 'ASK-118-EM-INSTANCE',
  SIGNALS_STORE: 'AE-768-signals-store',
  CUSTOM_MODEL_DETAIL: 'SSS-814-custom-models-shareable-link',
  LLM_PLAYGROUND: 'SSS-876-llm-playground',
};

export interface LDUser {
  email: string;
  customerId: string;
}

class FeatureFlagService {
  _ldClient: LDClient.LDClient;
  _featureFlags: { [key: string]: boolean } = {};

  init = async (envId: string, ldUser: LDUser) => {
    if (!envId || !ldUser) {
      throw new Error('Error initializing feature flag service');
    }
    this._ldClient = LDClient.initialize(envId, {
      key: ldUser.email,
      custom: {
        customer_id: ldUser.customerId,
      },
    });
    await new Promise<void>(resolve => {
      this._ldClient.on('ready', () => {
        Object.values(FEATURE_FLAGS).forEach(flagName => {
          this._featureFlags[flagName] = this._ldClient.variation(flagName, false);
        });
        resolve();
      });
    });
  };

  getFlag = (flag: string): boolean => {
    if (_.isEmpty(this._featureFlags)) {
      throw new Error(
        'FeatureFlagService was not properly initialized.  You must "await init()" before calling "getFlag()"'
      );
    }
    return this._featureFlags[flag] || false;
  };
}

export const featureFlagService = new FeatureFlagService();
